import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'

if (typeof window !== 'undefined') {
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [24, 36],
    iconAnchor: [12, 36]
  })

  L.Marker.prototype.options.icon = DefaultIcon
}

const LeafletMap = () => {
  if (typeof window === 'undefined') {
    return null
  }

  const position = [52.1785408, 21.0232625]

  return (
    <MapContainer
      center={position}
      zoom={11}
      className='leafletmap'
      style={{ height: '100%', borderRadius: '32px' }}
      scrollWheelZoom={false}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} />
    </MapContainer>
  )
}

export default LeafletMap
