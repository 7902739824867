import React from 'react'
import { Container, Flex, Box, Link, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ListItem from '@solid-ui-components/ListItem'
import FlexImage from '@solid-ui-components/FlexImage'
import FlexContent from '@solid-ui-components/FlexContent'
import FlexOverlapFade from '@solid-ui-components/FlexOverlapFade'
import ContentIcon from '@solid-ui-components/ContentIcon'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import PhoneLink from '@solid-ui-components/PhoneLink'
import EmailLink from '@solid-ui-components/EmailLink'
import LeafletMap from '@solid-ui-components/LeafletMap'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  specialText: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  }
}

const FeaturesWithPhotoBlock02 = ({
  content: { text, collection, buttons },
  reverse
}) => (
  <Container sx={{ position: `relative` }}>
    <Flex
      sx={{
        alignItems: [null, `center`],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          reverse ? `row-reverse` : `row`
        ],
        mx: [null, null, null, -4]
      }}
    >
      <FlexImage reverse={reverse}>
        <Container sx={{ height: ['300px', '600px', null, null] }}>
          <LeafletMap />
        </Container>
      </FlexImage>
      <FlexContent reverse={reverse} sx={{ mb: [null, null, null, 5] }}>
        {collection
          ?.filter(props => !!props.text)
          ?.map((props, index) => {
            const textContent = props.text?.[0]?.text
            const iconContent = props.icon
            const phoneEmailSx = color => ({
              display: props.icon ? 'flex' : 'block',
              alignItems: 'center',
              justifyContent: ['center', 'left'],
              textAlign: ['center', 'left'],
              color
            })

            if (textContent?.replace(/ /g, '').match(/^\+\d+$/)) {
              return (
                <Box key={index} sx={phoneEmailSx(props.text?.[0]?.color)}>
                  <ContentIcon content={iconContent} size='sm' mr='3' />
                  <PhoneLink text={textContent} />
                </Box>
              )
            } else if (
              textContent?.match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
              )
            ) {
              return (
                <Box key={index} sx={phoneEmailSx(props.text?.[0]?.color)}>
                  <ContentIcon content={iconContent} size='sm' mr='3' />
                  <EmailLink text={textContent} />
                </Box>
              )
            }

            return (
              <Box
                key={index}
                sx={{
                  display: props.icon ? 'flex' : 'block',
                  alignItems: 'center',
                  justifyContent: ['center', 'left'],
                  textAlign: ['center', 'left']
                }}
              >
                <ContentIcon content={props.icon} mr='3' />
                <ContentText content={props.text} />
              </Box>
            )
          })}
        {collection && (
          <>
            <Divider space={3} />
            <Flex sx={{ flexWrap: `wrap`, maxWidth: 500, m: -2 }}>
              {collection
                .filter(props => !props.text)
                .map((props, index) => (
                  <Reveal
                    key={`item-${index}`}
                    effect='fadeInPop'
                    delay={1 + 0.2 * (index + 1)}
                    css={css({ flexBasis: [`1`, `1/3`] })}
                  >
                    <ListItem {...props} middle />
                  </Reveal>
                ))}
            </Flex>
          </>
        )}
        {buttons && (
          <Box sx={{ textAlign: [`center`, `left`] }}>
            <Divider space={3} />
            <ContentButtons content={buttons} />
          </Box>
        )}
      </FlexContent>
    </Flex>
    <FlexOverlapFade direction={reverse ? 'ltr' : 'rtl'} />
  </Container>
)

export default WithDefaultContent(FeaturesWithPhotoBlock02)
