import React from 'react'
import { graphql } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import FeaturesWithMap from '@solid-ui-blocks/FeaturesWithMap/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'

const styles = {
  heroContainer: {
    pt: [6, 6],
    textAlign: 'center',
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: `full`,
      height: `3/5`,
      top: 0,
      right: 0
    },
    '::before': {
      zIndex: -3,
      bg: `#E8EDF3`
    }
  },
  featuresWithMapContainer: {
    px: [3, 4]
  }
}

const ContactPage = props => {
  const { t } = useI18next()
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes, t)

  return (
    <Layout theme={theme} {...props}>
      <Seo title={t('Kontakt')} />
      <Header content={content['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
        <Divider space='4' />
        <Container
          variant='cards.paper-lg'
          sx={styles.featuresWithMapContainer}
        >
          <FeaturesWithMap content={content['features-with-map']} />
        </Container>
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query contactBlockContent($language: String!) {
    allBlockContent(filter: { page: { in: ["site/contact", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ContactPage
